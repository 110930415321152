.footerstyle1 {
    background-color: #ff7400;
  }
  .footer1 {
    color: #ffffff;
    font-size: 20px;
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto;
    
  }
  .row1 {
    display: flex;    
    justify-content: space-around;
    flex-wrap: wrap;
  }
.noStyle1{
  text-decoration: none;
  
  color: #ffffff;
  border-bottom: 1px solid #ffffff;
}
.title123
{
  border-left: 4px #000 solid;color:#000;font-Weight:bold;padding-right: 5px;
}
a:hover{color: #000000;}
@media screen and (max-width: 600px) {
  .footer1{
    padding-left: 10px;
    font-size: 20px;
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto;
  }
  .row1{
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    border-bottom: 1px solid #ffffff; 
    border-width: 100%;   
  }
  
  .noStyle1{
    text-decoration: none;    
    color: #ffffff;
  }
}