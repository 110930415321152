// .postionhold{
// 	position: relative;
	
// 	object-fit: fill;
// 	overflow: hidden;
// 	width: 100%;
//   height: 50%;
// }
body{
	
	display: flex;
	justify-content: center;
	align-items: center;
  }
  
  .slider12{
	width: 100%;
	height: 800px;
	border-radius: 10px;
	overflow: hidden;
  }
  
  .slides12{
	width: 500%;
	height: 500px;
	display: flex;
  }
  
  .slides12 input{
	display: none;
  }
  
  .slide123{
	width: 20%;
	transition: 2s;
  }
  
  .slide123 img{
	width: 100%;
	height: 1000px;
  }
  
  /*css for manual slide navigation*/
  
  .navigation-manual{
	position: absolute;
	width: 800px;
	margin-top: -40px;
	display: flex;
	justify-content: center;
	text-align: center;
  }
  
  .manual-btn{
	border: 2px solid #40D3DC;
	padding: 5px;
	border-radius: 10px;
	cursor: pointer;
	transition: 1s;
  }
  
  .manual-btn:not(:last-child){
	margin-right: 40px;
  }
  
  .manual-btn:hover{
	background: #40D3DC;
  }
  
  #radio1:checked ~ .first{
	margin-left: 0;
  }
  
  #radio2:checked ~ .first{
	margin-left: -20%;
  }
  
  #radio3:checked ~ .first{
	margin-left: -40%;
  }
  
  #radio4:checked ~ .first{
	margin-left: -60%;
  }
  
  /*css for automatic navigation*/
  
  .navigation-auto{
	position: absolute;
	display: flex;
	width: 800px;
	justify-content: center;
	margin-top: 460px;
  }
  
  .navigation-auto div{
	border: 2px solid #40D3DC;
	padding: 5px;
	border-radius: 10px;
	transition: 1s;
  }
  
  .navigation-auto div:not(:last-child){
	margin-right: 40px;
  }
  
  #radio1:checked ~ .navigation-auto .auto-btn1{
	background: #40D3DC;
  }
  
  #radio2:checked ~ .navigation-auto .auto-btn2{
	background: #40D3DC;
  }
  
  #radio3:checked ~ .navigation-auto .auto-btn3{
	background: #40D3DC;
  }
  
  #radio4:checked ~ .navigation-auto .auto-btn4{
	background: #40D3DC;
  }
  @media screen and (max-width: 600px){
	
.slider{
	width: 800px;
	height: 400px;
	border-radius: 10px;
	overflow: hidden;
  }
  
  .slides{
	width: 500%;
	height: 500px;
	display: flex;
  }
  
  .slides input{
	display: none;
  }
  
  .slide{
	width: 20%;
	transition: 2s;
  }
  
  .slide img{
	width: 800px;
	height: 500px;
  }
  
  /*css for manual slide navigation*/
  
  .navigation-manual{
	position: absolute;
	width: 800px;
	margin-top: -40px;
	display: flex;
	justify-content: center;
  }
  
  .manual-btn{
	border: 2px solid #40D3DC;
	padding: 5px;
	border-radius: 10px;
	cursor: pointer;
	transition: 1s;
  }
  
  .manual-btn:not(:last-child){
	margin-right: 40px;
  }
  
  .manual-btn:hover{
	background: #40D3DC;
  }
  
  #radio1:checked ~ .first{
	margin-left: 0;
  }
  
  #radio2:checked ~ .first{
	margin-left: -20%;
  }
  
  #radio3:checked ~ .first{
	margin-left: -40%;
  }
  
  #radio4:checked ~ .first{
	margin-left: -60%;
  }
  
  /*css for automatic navigation*/
  
  .navigation-auto{
	position: absolute;
	display: flex;
	width: 800px;
	justify-content: center;
	margin-top: 460px;
  }
  
  .navigation-auto div{
	border: 2px solid #40D3DC;
	padding: 5px;
	border-radius: 10px;
	transition: 1s;
  }
  
  .navigation-auto div:not(:last-child){
	margin-right: 40px;
  }
  
  #radio1:checked ~ .navigation-auto .auto-btn1{
	background: #40D3DC;
  }
  
  #radio2:checked ~ .navigation-auto .auto-btn2{
	background: #40D3DC;
  }
  
  #radio3:checked ~ .navigation-auto .auto-btn3{
	background: #40D3DC;
  }
  
  #radio4:checked ~ .navigation-auto .auto-btn4{
	background: #40D3DC;
  }
		
  }