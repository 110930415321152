
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap');


.about-left{
   
    display: flex;
   
    align-items: center;
    justify-content: center;
    color:#fff
}
.gridContainer{
      
        display: grid;
        grid-template-columns: auto auto ;
        text-align: center;
       align-items: center;
       border: 1px solid #000;
       padding: 20px 20px 20px 20px;
       border-radius: 25px;
}
.gridalign{
    padding: 10px 10px 10px 10px;
}
.gridItemstyle{
font-size: 30px;
}
.grid-item{
   
    font-weight: bold;
}
.about-left-content > div{
    background: #FF7400;
    padding: 20px 40px 20px 40px;
    text-align: center;
    
    border-radius: 12px ;
}
.about-left-content{
    box-shadow: 0px 0px 18px -1px rgba(0, 0, 0, 0.39);
    -webkit-box-shadow: 0px 0px 18px -1px rgba(0, 0, 0, 0.39);
    -moz-box-shadow: 0px 0px 18px -1px rgba(0, 0, 0, 0.39);
    border-radius: 12px;
    width:80%;
}
.about-img img{
    display: block;
    width: 200px;
}
.about-img{
    width: 200px;
    height: 200px;
    overflow: hidden;
    border-radius: 50%;
    transition: all 0.5s ease-in-out;
}
.shadow{
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    width: 200px;
    height: 200px;
}



.about-left-content h2{
    font-size: 2rem;
    margin: 2.2rem 0 0.6rem 0;
    line-height: 1.2;
    padding-bottom: 1rem;
    border-bottom: 2px solid #000000;
}
.about-left-content h3{
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 5px;
    margin-top: 1.2rem;
}
.icons{
    background: #12192c;
    display: flex;
    justify-content: center;
    padding: 0.8rem 0;
    border-radius: 0 0 12px 12px;
}
.icons li{
    list-style-type: none;
    background: #B721FF;
    color: #fff;
    width: 40px;
    height: 40px;
    margin: 0 0.5rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
}
.icons li:hover{
    background: #edffec;
    color: #000;
}
.about-right{
    background: #12192c;
   
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px;
    border-radius: 20px;
    color:#fff
}
.about-right h1{
    font-size: 5rem;
    text-transform: uppercase;
}
.about-right h1 span{
    color: #B721FF;
}
.about-right h2{
    font-weight: 600;
}
.about-btns{
    display: flex;
    margin: 2rem 0;
}
.btn{
    border: none;
    font-size: 0.9rem;
    text-transform: uppercase;
    border: 2px solid #fff;
    border-radius: 20px;
    padding: 0.55rem 0;
    width: 130px;
    font-weight: 600;
    background: transparent;
    margin: 0 0.5rem;
    cursor: pointer;
    color:#fff
}
.btn.btn-pink{
    background: #B721FF;
    color: #fff;
    border-color: #B721FF;
    transition: all 0.5s ease-in-out;
}
.btn.btn-pink:hover{
    background: transparent;
    border-color: #fff;
    color: #fff;
}
.btn.btn-white{
    transition: all 0.5s ease-in-out;
}
.btn.btn-white:hover{
    background: #B721FF;
    border-color: #B721FF;
    color: #fff;
}
.about-para p{
    font-weight: 300;
    padding: 0.5rem;
    opacity: 0.8;
}

@media screen and (min-width: 660px){
    .about-wrapper{
        display: grid;
        grid-template-columns: repeat(2, 3fr);
    }

    .about-left{
        position: relative;
    }
    .about-left-content{
        position: relative;
       
    
    }
}
.credit{
    text-align: center;
    color: #fff;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.credit a{
    text-decoration: none;
    color:#B721FF;
    font-weight: bold;
} 


