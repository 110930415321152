header{
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    background:#ff7400 ;
    display: flex;
    align-items: center;
    justify-content:space-between;
    flex-wrap: wrap;
    z-index: 1000;
}
header .navbar1 ul{
    list-style: none;
    
}
header .navbar1 ul li{
    position: relative;
    float: left;
  align-items: center;
    text-align: center;
    justify-content: center;
  
}
header .navbar1 ul li a{
    display: block;
    padding: 20px;
    color: #fff;
    font-size: 22px;
    background-color: #ff7400;
}
header .navbar1 ul li a:hover{
   
    color: #000000;
}
header .navbar1 ul li ul{
    position: absolute;
    left: 0;
    width: 170px;
    background: #FFF;
    display: none;
}
header .navbar1 ul li ul li{
    width: 100%;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    background: #FFF;
}
header .navbar1 ul li ul li ul{
    left: 200px;
}

header .navbar1 ul li:hover>ul{
    display: initial;

}
  button {
    
    cursor: pointer;
    border: 1px solid #000;
    border-radius: 4px;
    font-weight: 600;
    justify-content: center;
    width: 100px;    
    box-shadow: 0 0 20px rgba(104, 85, 224, 0.2);
    transition: 0.4s;
  }
  button:hover {
    color: white;
    width:;
    box-shadow: 0 0 20px rgba(104, 85, 224, 0.6);
    background-color: #000;
  }
  
  .main-header {
    width: 100%;
    z-index: 99;     
    border-bottom: 1px solid;
    border-color: rgba(0, 0, 0, 0.1);
    @include md {
        padding: 0 40px;
    }
    .header-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 1200px;
        margin: 0 auto;
        @include md {
            height: 80px;
        }
    }
    
    
        
    
    &.sticky-header {
        background-color: #fff;
        
        position: sticky;
        top: 0;
        transform: translateY(-60px);
        animation: StickyHeader 0.3s ease forwards;
    }
}
#menu-bar{
    display: none;
}
header label{
    font-size: 20px;
    color: #fff ;
    cursor: pointer;
    display: none;
}
@media(max-width:768px) {
    header label{
        display: initial;

    }
    header .navbar1{
        position: absolute;
        display: none;
        top: 100%;
        background-color: #ff7400;
        border-top: 1px solid #000;
    }
    header .navbar1 ul li,button{
        width: 100%;
        float: left;
    }
    header .navbar1 ul li ul{
        position: relative;
        width: 100%;
    }
    header .navbar1 ul li ul li{
        background-color: #FFF;
    }
    header .navbar1 ul li ul li ul{
        width: 100%;
        left: 0;
    }
    #menu-bar:checked ~ .navbar1{
        display: initial;
    }
}

@keyframes StickyHeader {
    0% {
        transform: translateY(-60px);
    }
    100% {
        transform: translateY(0);
    }
}