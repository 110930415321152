.float {
	position: fixed;
	width: 50px;
	height: 50px;
	bottom: 20px;
	right: 90px;
	background-color: #25d366;
	color: #000000;
	border-radius: 50%;
	text-align: center;
	font-size: 30px;
	box-shadow: 2px 2px 3px #999;
	z-index: 100;
}

.my-float {
	margin-top: 16px;
}