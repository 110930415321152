.contact{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 100%;
    width: 100%;
   padding-left: 30%;
   padding-right: 30%;
    position: relative;
    
}
