.scroll-to-top-button {
    display: block;
    justify-content: center;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    font-size: 20px;
    cursor: pointer;
  }
  