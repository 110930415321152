.sidebar{
    list-style-type: none;
    margin-left: 0;
    background-color: #FF7400;
    margin-bottom: 7px;
    padding: 5px;
    border-right: 6px #000000 solid;
    font-size: 19px;
    font-weight: 400;
    color: #000000;
    display: block;
    font-family: "Montserrat";
}
.image{height:250px; width:100%}