.wholebody {
    display: flex;
    justify-content: space-around;
    text-transform: uppercase;
    text-align: center;
    left: 25%
}
.subheading {
    font-size: 20px;
    color: #000;
}
.wrapper {

    padding: 10px;
    border-radius: 10px;
    border-style: solid;
    border-color: #000;
}

.field {
    margin: 10px;
   

}

.center {
    display: flex;

}

.Label {
    font-size: 16px;
}

input[type='range'] {
    width: 80%; 
    color: #000;
}

.abc {
    width: 100%;
    padding: 10px;
    font-size: 18px;
    cursor: pointer;
    border: 1px solid #fff;
}

.bottom {
    border-bottom: 1px solid #000;
}