.align {
    display: flex;
    padding: 10px;
}

.inneralign {
    position: relative;
    width: 35%;
    flex-wrap: wrap;
    align-content: flex-start
}

.content {
    padding-left: 20px;
    width: 100%
}

.subheading {
    font-size: 20px;
    color: #000;
}
.subcontent{
    font-size: 18px;
}
.header-style{
   padding: 40px 0px 40px 0px !important;
    margin-bottom: 20px;
    text-align: center;
    background: #ffffff;
    font-size: 25px;
    line-height: 1.4;
    
}
.title {
    color: #233d62;
    font-size: 20px;
    font-weight: normal;
    margin: 0 0 15px;
    line-height: 1.4;
    color: #FF7400;
}
@media (max-width: 768px) {
    .align {
        display: flex;
        text-align: center;
        padding: 10px;
        flex-wrap: wrap;
    }
    .inneralign {
        position: relative;
        width: 100%;
        flex-wrap: wrap;
        align-content: flex-start
    }
    .subheading {
        line-height: 2;
        font-size: 20px;
    }
    .image{height:250px; width:250px}
    .content {
      max-width: 100%;
      padding: 10px;
    }
  }