$bg: #Fff;

.product {
  color: #FF7400;

  font-size: 50px;
  font-weight: normal;
  line-height: 60px;
  margin: 10px 0 20px;
  text-transform: uppercase;
  
  margin: 10px 0 24px;
  text-align: center;
}

.service {
  color: #FF8517;
  font-family: 'Montserrat';
  font-size: 25px;
  line-height: 24px;
  margin: 0 0 24px;
  text-align: center;
}

body {
  background-color: $bg;
}

.h2border {
  background-color: #FF7400;
  width: 100%;
}

.titlesub {
  text-align: center;
}

.container11 {
  overflow: hidden;
  text-align: center;
  width: 300px;
  height: 400px;

  position: relative;
  top: 0;
  right: 0;
  left: 0;
  bottom: 10px;
  margin: auto;

  background: url("../../images/SB.jpg")no-repeat center center;
  background-color: rgb(11, 103, 131);
  background-size: cover;
  cursor: pointer;

  -webkit-box-shadow: 0 0 5px #000;
  box-shadow: 0 0 5px #000;
}

.container1 {
  overflow: hidden;
  text-align: center;
  width: 300px;
  height: 400px;

  position: relative;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  object-fit: fill;
  background: url("../../images/mutual_fund.jpg")no-repeat center center;
  background-color: rgb(11, 103, 131);
  background-size: cover;
  cursor: pointer;

  -webkit-box-shadow: 0 0 5px #000;
  box-shadow: 0 0 5px #000;
}

.container2 {
  overflow: hidden;
  text-align: center;
  width: 300px;
  height: 400px;

  position: relative;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  // object-fit: fill;
  background: url("../../images/insurance.jpg")no-repeat center center;
  background-color: rgb(216, 44, 44);
  background-size: cover;
  cursor: pointer;

  -webkit-box-shadow: 0 0 5px #000;
  box-shadow: 0 0 5px #000;
}

.container3 {
  overflow: hidden;
  text-align: center;
  width: 300px;
  height: 400px;

  position: relative;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;

  background: url("../../images/gunny.jpg")no-repeat center center;
  background-color: black;
  background-size: cover;
  cursor: pointer;

  -webkit-box-shadow: 0 0 5px #000;
  box-shadow: 0 0 5px #000;
}

.overlay {
  width: 100%;
  height: 100%;
  color: #000;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 2fr 2fr 1fr;

  background: rgba(231, 224, 224, 0.9);
  color: $bg;
  opacity: 0;
  transition: all 0.5s;

  font-family: 'Montserrat';
}


.items {
  padding-left: 0px;
  letter-spacing: 3px;

}

.head {
  font-size: 18px;
  line-height: 30px;
  
  transform: translateY(40px);
  transition: all 0.7s;
  .hoveritem{
    color: #FF7400;
  }
  hr {
    display: block;
    width: 0;

    border: none;
    border-bottom: solid 2px #000000;

    position: relative;
    bottom: 0;

    transition: all .5s;
  }
  
}

.price {
  font-size: 22px;
  line-height: 10px;
  font-weight: bold;
  opacity: 0;
  transform: translateY(40px);
  transition: all 0.7s;

  .old {
    text-decoration: line-through;
    color: lighten(rgb(77, 77, 77), 40%);
  }
}



.container11:hover .overlay {
  opacity: 1;
  p:hover{
   color: #000000;
  }
  & .head {
    transform: translateY(0px);
  }

  & hr {
    width: 90%;
    transition-delay: 0.4s;
    
  }

  & .price {
    transform: translateY(0px);
    transition-delay: 0.3s;
    opacity: 1;
  }

  & .cart {
    transform: translateY(0px);
    transition-delay: 0.6s;
    opacity: 1;
  }


}

.container1:hover .overlay {
  opacity: 1;
  p:hover{
    color: #000000;
   }
  & .head {
    transform: translateY(0px);
  }

  & hr {
    width: 90%;
    transition-delay: 0.4s;
  }

  & .price {
    transform: translateY(0px);
    transition-delay: 0.3s;
    opacity: 1;
  }

  & .cart {
    transform: translateY(0px);
    transition-delay: 0.6s;
    opacity: 1;
  }
}

.container2:hover .overlay {
  opacity: 1;
  p:hover{
    color: #000000;
   }
  & .head {
    transform: translateY(0px);
  }

  & hr {
    width: 90%;
    transition-delay: 0.4s;
  }

  & .price {
    transform: translateY(0px);
    transition-delay: 0.3s;
    opacity: 1;
  }

  & .cart {
    transform: translateY(0px);
    transition-delay: 0.6s;
    opacity: 1;
  }
}

.container3:hover .overlay {
  opacity: 1;
  p:hover{
    color: #000000;
   }
  & .head {
    transform: translateY(0px);
  }

  & hr {
    width: 90%;
    transition-delay: 0.4s;
  }

  & .price {
    transform: translateY(0px);
    transition-delay: 0.3s;
    opacity: 1;
  }

  & .cart {
    transform: translateY(0px);
    transition-delay: 0.6s;
    opacity: 1;
  }
}
@media screen and (max-width(660px)) {
  
}