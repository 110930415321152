.align {
    display: flex;
    padding: 10px;
}

.inneralign {
    position: relative;
    width: 35%;
    flex-wrap: wrap;
    align-content: flex-start
}
.blur{
    background-image: url('../../images/business1.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%; /* Set the width and height to fill the container */
    height: 100%;
   opacity: 0.5;
}
.content {
    padding-left: 20px;
    width: 100%;
    
}

.subheading {
    font-size: 20px;
    color: #000;
}

.title {
    color: #233d62;
    font-size: 18px;
    font-weight: normal;
    margin: 0 0 15px;
    line-height: 1.4;
}
p{
    font-size: 18px;
}
@media (max-width: 768px) {
    .align {
        display: flex;
        text-align: center;
        padding: 10px;
        flex-wrap: wrap;
    }
    .inneralign {
        position: relative;
        width: 100%;
        flex-wrap: wrap;
        align-content: flex-start
    }
    .subheading {
        line-height: 2;
        font-size: 20px;
    }
    .image{height:250px; width:250px}
    .content {
      max-width: 100%;
      padding: 10px;
    }
  }